<template>
  <div class="remember-success">
    <h3>Проверьте почту!</h3>
    <h5>Мы отправили вам новый пароль</h5>
    <img
      src="/v2/auth/remember-success.png"
      alt="success"
    >
  </div>
</template>

<script>
export default {
  name: 'RememberSuccess',
};
</script>

<style scoped lang="scss">

.remember-success {
  font-family: 'TildaSans', sans-serif;
  text-align: center;

  h3 {
    color: $black;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.2px;
    margin-bottom: 10px;

    @include media-down(mobile) {
      margin-bottom: 8px;
      font-size: 24px;
      letter-spacing: 0;
    }
  }

  h5 {
    color: $black;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 16px;

    @include media-down(mobile) {
      margin-bottom: 32px;
      font-size: 20px;
      font-weight: 600;
    }
  }
}

</style>
