<template>
  <div
    class="remember-form"
    @keypress.enter="submit"
  >
    <form-email-field
      ref="emailField"
      v-model="email"
      :submit-error="submitError"
      class="login-form__email"
      @clearSubmitError="clearSubmitError"
    />
    <div class="m-btn__pull">
      <button
        type="submit"
        class="m-btn"
        :disabled="sending"
        @click="submit"
      >
        Восстановить
      </button>
    </div>
  </div>
</template>

<script>
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';

export default {
  name: 'RememberPasswordForm',
  components: { FormEmailField },
  data: () => ({
    email: '',
    submitError: [],
    sending: false,
  }),
  methods: {
    clearSubmitError() {
      this.submitError = [];
    },
    submit() {
      if (!this.$refs.emailField.validate()) {
        return false;
      }

      this.sending = true;

      this.$api.remember(this.email)
        .then((response) => {
          const data = response.data?.forgotPassword;
          if (!data.success) {
            this.submitError = data.errors;
            this.$emit('error');
          } else {
            this.$emit('success', { email: this.email });
          }
        })
        .catch(() => {
          this.$emit('error');
        })
        .finally(() => {
          this.sending = false;
        });

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>

.remember-form {
  margin-top: 50px;

  @include media-down(tablet) {
    margin-top: 32px;
  }

  .m-btn__pull {
    border-radius: 15px;
  }

  .login-form__email {
    margin-bottom: 20px;

    @include media-down(tablet) {
      margin-bottom: 8px;
    }
  }
}

</style>
